import '../styles/index.scss';
import React, { Component } from "react";
import SEO from "../components/seo";
import THHero from '../components/th-hero';
import Apartments from '../components/apartments';
import Footer from '../components/footer';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';

const paragraph = "14 роскошных таунхаусов расположились в отдельной закрытой зоне комплекса, но в то же время являются неотъемлемой частью архитектурного ансамбля. Мы детально продумали планировочные решения, чтобы каждый метр вашего дома был максимально полезно использован. Каждый таунхаус имеет оборудованную зону парковки для 2-х автомобилей и собственный уютный двор! Приобретая таунхаус, вы наконец-то сможете реализовать все мечты об идеальном доме: организуете уютную зону барбекю для встречи с друзьями, будете завтракать на свежем воздухе и, наконец-то, заведёте собаку!";

class Townhouses extends Component {
  componentDidMount() {
    const bodyOverflow = getComputedStyle(document.documentElement).overflow;
    if (bodyOverflow === 'hidden')
      document.documentElement.style.overflow = 'visible';
  }

  render() {
    console.log(this.props.coordinates)
    return (
      <div className="townhouses-page plans-page page">
        <SEO title="Таунхаусы" />

        <THHero
          bgImg={this.props.data.bg.childImageSharp.fluid}
          coordinates={this.props.coordinates}
          heading="ТАУНХАУСЫ"
          paragraph={paragraph}
          bigBtnText="Выбрать Квартиры"
          bigBtnLink="/flats" />
        <Apartments isPage />
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = ({ thCoordinates }) => ({ coordinates: thCoordinates });
export default connect(mapStateToProps)(Townhouses);


export const pageQuery = graphql`
  query {
    bg: file(relativePath: { eq: "bg/townhouses_bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
