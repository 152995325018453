import React, { Component } from 'react';
import { PillarVertical } from '../pillars';
import { LineLeft, LineBottom } from '../lines';
import { Link } from 'gatsby';
import { TableHead, TableRow } from './table';
import { connect } from 'react-redux';
import { selectBlock } from '../../store/actions';
import Layout from '../layout';
import Container from '../container';

class Apartments extends Component {
  handlePrev = _e => {
    const { select, selectedBlock, blocks } = this.props;

    let prev = selectedBlock - 1;
    if (prev < 0) prev = blocks.length - 1;
    select(prev);
  };

  handleNext = _e => {
    const { select, selectedBlock, blocks } = this.props;

    let next = selectedBlock + 1;
    if (next === blocks.length) next = 0;
    select(next);
  };

  selectType = e => this.props.select(+e.target.getAttribute('datatype'));

  toggleDropdown = e => e.currentTarget.classList.toggle('active');

  render() {
    const { blocks, selectedBlock, flatTypes, isPage } = this.props;

    return (
      <Layout id="apartments" className="apartments section-bg-dark">
        <Container>
          <div className="content">
            <LineBottom />

            <div className="left-side">
              <PillarVertical style={{ top: -110, left: "100%" }} />

              <div className="inner">
                <LineLeft style={{ top: -110 }} />

                <img src={`/plans/townhouses/${blocks[selectedBlock].img}`} alt="plan" />

                <div className="controls">
                  <button className="prev" onClick={this.handlePrev}>
                    <img src="/icons/arrow.svg" alt="previous item" />
                  </button>
                  <button className="next" onClick={this.handleNext}>
                    <img src="/icons/arrow.svg" alt="next item" />
                  </button>
                </div>
              </div>

              <Link to={isPage ? '/contactus' : '/townhouses'}>
                <button>{isPage ? 'СВЯЗАТЬСЯ С НАМИ' : 'ВЫБРАТЬ ПЛАНИРОВКИ'}</button>
              </Link>
            </div>

            <div className="right-side">
              <div className="title">
                <h2>ПЛАНИРОВКИ</h2>

                <div className="plans-dropdown" onClick={this.toggleDropdown}>
                  <span>
                    { flatTypes[blocks[selectedBlock].type].type } ({ flatTypes[blocks[selectedBlock].type].totalSquare }{' м'}<sup>2</sup>)
                  </span>

                  <div className="dropdown-arrow">
                    <span></span>
                  </div>

                  <ul className="section-bg-dark">
                    { flatTypes.map((t, idx) => (
                      <li datatype={idx} key={Math.random()} onClick={this.selectType}>{ t.type } ({t.totalSquare})</li>
                    )) }
                  </ul>
                </div>
                <LineBottom style={{ left: -110 }} />
              </div>

              <div className="apartment-parameters">
                <div className="tables">
                    <div className="table">
                      <h3>{ flatTypes[blocks[selectedBlock].type].name }</h3>

                      <table>
                        <TableHead />
                        <tbody>
                          {flatTypes[blocks[selectedBlock].type].rooms.map((x, idx) =>
                            <TableRow idx={idx} name={x.name} square={x.square} key={x.name.toString() + idx} />
                          )}
                        </tbody>
                      </table>
                    </div>
                </div>

                <Link to={isPage ? '/contactus' : '/townhouses'}>
                  <button>{isPage ? 'СВЯЗАТЬСЯ С НАМИ' : 'ВЫБРАТЬ ПЛАНИРОВКИ'}</button>
                </Link>
              </div>
            </div>

          </div>
        </Container>
      </Layout>
    )
  }
};

const mapStateToProps = ({ blocks, flatTypes, selectedBlock }) => ({
  blocks,
  flatTypes,
  selectedBlock
});
const mapDispatchToProps = dispatch => ({
  select: idx => dispatch(selectBlock(idx))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Apartments);
