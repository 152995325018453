import React from 'react';

export const TableHead = () => (
  <thead>
    <tr>
      <th>НОМЕР</th>
      <th>НАЗВАНИЕ КОМНАТЫ</th>
      <th>ПЛОЩАДЬ М<sup>2</sup></th>
    </tr>
  </thead>
);

export const TableRow = ({ idx, name, square }) => (
  <tr>
    <td>{ ++idx }</td>
    <td>{ name }</td>
    <td>{ square } м<sup>2</sup></td>
  </tr>
)